import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/icons/foot-menu/icon-home.png";
import ExchangeIcon from "../assets/images/icons/foot-menu/icon-exchange.png";
import DepositIcon from "../assets/images/icons/foot-menu/icon-deposit.png";
import MyAccountIcon from "../assets/images/icons/foot-menu/icon-myaccount.png";
import ProfileImg from "../assets/images/profile-img.jpg";
import DepositImg from "../assets/images/icons/icon-deposit.png";
import WithdrawImg from "../assets/images/icons/icon-withdrawal.png";
import BettingImg from "../assets/images/icons/icon-bet-records.png";
import TransactioImg from "../assets/images/icons/icon-records.png";
import UserInfoImg from "../assets/images/icons/icon-profile.png";
import BonusImg from "../assets/images/icons/icon-bonus.png";
import ReferralImg from "../assets/images/icons/icon-referral.png";
import WhatsappImg from "../assets/images/icons/icon-whatsapp.png";
import EmailImg from "../assets/images/icons/icon-email.png";
import FacebookImg from "../assets/images/icons/icon-facebook.png";
import TelegramImg from "../assets/images/icons/icon-telegram.png";
import LogoutIcon from "../assets/images/icons/logout-icon.svg";

export default function BottomNav() {
  const location = useLocation();
  const { appDetails } = useSelector((state) => state.app);
  const { user, wallet } = useSelector((state) => state.auth);
  const [activePage, setActivePage] = useState("");
  const dispatch = useDispatch();

  const footerPages = ["home", "spmsports", "gatewaylist", "sportsbook", "/"];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const [isToggled, setIsToggled] = useState(false);
  const toggleDiv = () => {
    setIsToggled(!isToggled);
  };
  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isToggled) {
      document.body.classList.add("profile-open");
      htmlElement.style.overflow = "hidden";
    } else {
      document.body.classList.remove("profile-open");
      htmlElement.style.overflow = "auto";
    }
    return () => {
      document.body.classList.remove("profile-open");
      htmlElement.style.overflow = "auto";
    };
  }, [isToggled]);

  return (
    <>
      <footer className="footer d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 ">
          <ul className="list-unstyled">
            <li>
              <a
                className={activePage === "spmsports/cricket" ? "active" : ""}
                href="/"
              >
                <img src={HomeIcon} alt="home" />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "spmsports/cricket" ? "active" : ""}
                href="/spmsports/cricket"
              >
                <img src={ExchangeIcon} alt="exchange" />
                <span>Exchange</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "gatewaylist" ? "active" : ""}
                href="/gatewaylist"
              >
                <img src={DepositIcon} alt="deposit" />
                <span>Deposit</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" onClick={toggleDiv}>
                <img src={MyAccountIcon} alt="my Account" />
                <span>My Account</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <div className="my_menu d-lg-none">
        <div className="headSec">
          <div className="close-btn" onClick={toggleDiv}></div>
          {/* <div className="close-btn" onClick={toggleVisibility}></div> */}
          <div className="head-content">
            <div className="profileImg">
              <img src={ProfileImg} alt="profile" />
            </div>
            <div className="profileInfo">
              <h5>{user?.mstruserid}</h5>
            </div>
          </div>
        </div>

        <div className="bodySec">
          <div className="mainWallet comn-box">
            <p>Main Wallet</p>
            <h6>INR 0</h6>
          </div>

          <div className="funds comn-box">
            <h5 className="headings">Funds</h5>
            <div className="funds-content flex-container">
              <a href="/gatewaylist">
                <img src={DepositImg} alt="deposit" />
                <span>Deposit</span>
              </a>
              <a href="/withdraw">
                <img src={WithdrawImg} alt="withdraw" />
                <span>Withdrawal</span>
              </a>
            </div>
          </div>

          <div className="history comn-box">
            <h5 className="headings">History</h5>
            <div className="history-content flex-container">
              <a href="/mybets">
                <img src={BettingImg} alt="betting records" />
                <span>Betting Records</span>
              </a>
              <a href="/cashier">
                <img src={TransactioImg} alt="transaction records" />
                <span>Transaction Records</span>
              </a>
            </div>
          </div>

          <div className="profile comn-box">
            <h5 className="headings">Profile</h5>
            <div className="profile-content flex-container">
              <a href="/profile">
                <img src={UserInfoImg} alt="profile" />
                <span>Personal Info</span>
              </a>
              <a href="/bonus">
                <img src={BonusImg} alt="bonus" />
                <span>Bonus</span>
              </a>
              <a href="/referral">
                <img src={ReferralImg} alt="referral" />
                <span>Referral</span>
              </a>
            </div>
          </div>

          <div className="contact comn-box">
            <h5 className="headings">Contact Us</h5>
            <div className="contact-content flex-container">
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={WhatsappImg} alt="whatsapp" />

                <span>WhatsApp</span>
              </a>
              <a href="mailto:support@metabets365">
                <img src={EmailImg} alt="email" />
                <span>Email</span>
              </a>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={FacebookImg} alt="facebook" />
                <span>Facebook</span>
              </a>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={TelegramImg} alt="telegram" />
                <span>Telegram</span>
              </a>
            </div>
          </div>

          <div className="logout comn-box">
            <div onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <img src={LogoutIcon} alt="logout" />
                <span>Log out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
