import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import HomeIcon from "../assets/images/icons/icon-home.png";
import ExchangeIcon from "../assets/images/icons/icon-exchangen.png";
import CasinoIcon from "../assets/images/icons/icon-casino.png";
import SportsbookIcon from "../assets/images/icons/icon-sport.png";
import SlotsIcon from "../assets/images/icons/icon-slot.png";
import CrashIcon from "../assets/images/icons/icon-crash.png";
import WhatsappIcon from "../assets/images/icons/icon-chat.png";

import InplayIcon from "../assets/images/icons/inplay-icon.png";
import CricketIcon from "../assets/images/icons/cricket-icon.png";
import SoccerIcon from "../assets/images/icons/soccer-icon.png";
import TenisIcon from "../assets/images/icons/tennis-icon.png";
import HorseRacingIcon from "../assets/images/icons/horseracing-icon.png";
import AllProviderIcon from "../assets/images/icons/icon-all-provider.svg";
import EvolutionIcon from "../assets/images/icons/evolution.png";
import EzugiIcon from "../assets/images/icons/ezugi.png";
import SupernowaIcon from "../assets/images/icons/supernowa.png";
import VivoIcon from "../assets/images/icons/vivo.png";
import WorldCosinoIcon from "../assets/images/icons/world-cosino.png";
import QtechIcon from "../assets/images/icons/qtech.png";
import SportbookIcon from "../assets/images/icons/icon-sportbook.png";
import NetentIcon from "../assets/images/icons/netent.png";
import RedTigerIcon from "../assets/images/icons/redtiger.png";
import EvoplayIcon from "../assets/images/icons/evoplay.png";
import FugasoIcon from "../assets/images/icons/fugaso.png";
import QuickSpinIcon from "../assets/images/icons/quick-spin.png";
import RTGIcon from "../assets/images/icons/rtg.png";
import SpribeIcon from "../assets/images/icons/spribe.png";

const MobLeftbar = ({ setShowAuthModals }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  const handleNavigation = (url, navigationState = {}) => {
    if (isAuth) {
      navigate(url, {
        state: navigationState,
      });
    } else {
      if (isMobile) {
        setShowAuthModals(true);
      } else {
        navigate(url);
      }
    }
  };

  const [activeMenu, setActiveMenu] = useState(null);
  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
  };

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            <ul>
              <li>
                <div
                  onClick={() => {
                    !isAuth ? navigate("/") : navigate("/sports");
                  }}
                  href="javascript:void(0)"
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  className={activeMenu === 2 ? "active" : ""}
                  onClick={() => handleMenuClick(2)}
                >
                  <img src={ExchangeIcon} alt="exchange" />
                  <span>Exchange</span>
                </div>
              </li>
              <li>
                <div
                  className={activeMenu === 3 ? "active" : ""}
                  onClick={() => handleMenuClick(3)}
                >
                  <img src={CasinoIcon} alt="casino" />
                  <span>Casino</span>
                </div>
              </li>
              <li>
                <div
                  className={activeMenu === 4 ? "active" : ""}
                  onClick={() => handleMenuClick(4)}
                >
                  <img src={SportsbookIcon} alt="sportsbook" />
                  <span>Sportsbook</span>
                </div>
              </li>
              <li>
                <div
                  className={activeMenu === 5 ? "active" : ""}
                  onClick={() => handleMenuClick(5)}
                >
                  <img src={SlotsIcon} alt="slots" />
                  <span>Slots</span>
                </div>
              </li>
              <li>
                <div
                  className={activeMenu === 6 ? "active" : ""}
                  onClick={() => handleMenuClick(6)}
                >
                  <img src={CrashIcon} alt="crash" />
                  <span>Crash</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <ul className="contact">
              <li>
                <a
                  className="wtp"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsappIcon} alt="whatsapp" />

                  <span> 24/7 LiveChat </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="sub-leftbar-wrapper">
        <div
          className={activeMenu === 2 ? "sub-leftbar active" : "sub-leftbar"}
        >
          <ul>
            <li>
              <a href="/spmsports/inplay">
                <img src={InplayIcon} alt="exchange" />
                <span>Inplay</span>
              </a>
            </li>
            <li>
              <a href="/spmsports/cricket">
                <img src={CricketIcon} alt="exchange" />
                <span>Cricket</span>
              </a>
            </li>
            <li>
              <a href="/spmsports/soccer">
                <img src={SoccerIcon} alt="exchange" />
                <span>Soccer</span>
              </a>
            </li>
            <li>
              <a href="/spmsports/tennis">
                <img src={TenisIcon} alt="exchange" />
                <span>Tennis</span>
              </a>
            </li>
            <li>
              <a href="/spmsports/Horse Racing">
                <img src={HorseRacingIcon} alt="exchange" />
                <span>Horse Racing</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={activeMenu === 3 ? "sub-leftbar active" : "sub-leftbar"}
        >
          <ul>
            <li>
              <a href="/casino">
                <img src={AllProviderIcon} alt="evolution" />
                <span>All</span>
              </a>
            </li>
            <li>
              <a href="/casino/evolution">
                <img src={EvolutionIcon} alt="evolution" />
                <span>Evolution</span>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <img src={EzugiIcon} alt="ezugi" />
                <span>Ezugi</span>
              </a>
            </li>
            <li>
              <a href="/casino/supernowa">
                <img src={SupernowaIcon} alt="supernowa" />
                <span>Supernowa</span>
              </a>
            </li>
            <li>
              <a href="/casino/worldcasino">
                <img src={WorldCosinoIcon} alt="vivo" />
                <span>Worldcasino</span>
              </a>
            </li>
            <li>
              <a href="/casino/vivo">
                <img src={QtechIcon} alt="vivo" />
                <span>Vivo</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={VivoIcon} alt="qtech" />
                <span>Qtech</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={activeMenu === 4 ? "sub-leftbar active" : "sub-leftbar"}
        >
          <ul>
            <li>
              <a href="/betby">
                <img src={SportbookIcon} alt="sportsbook" />
                <span>Sportsbook</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={activeMenu === 5 ? "sub-leftbar active" : "sub-leftbar"}
        >
          <ul>
            <li>
              <a href="/casino/qtech">
                <img src={NetentIcon} alt="netent" />
                <span>Netent</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={RedTigerIcon} alt="red tiger" />
                <span>Red Tiger</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={EvoplayIcon} alt="evoplay" />
                <span>Evoplay</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={FugasoIcon} alt="fugaso" />
                <span>Fugaso</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={QuickSpinIcon} alt="quick spin" />
                <span>Quick Spin</span>
              </a>
            </li>
            <li>
              <a href="/casino/qtech">
                <img src={RTGIcon} alt="rtg" />
                <span>RTG</span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={activeMenu === 6 ? "sub-leftbar active" : "sub-leftbar"}
        >
          <ul>
            <li>
              <a href="/casino/spribe/aviator">
                <img src={SpribeIcon} alt="spribe" />
                <span>Aviator</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobLeftbar;
